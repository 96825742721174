<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Agent change request</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Agent change request
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field
                        label="Name"
                        v-model="search.name"
                        v-on:keyup.enter="searchAgentChangeRequest"
                        @input="search.name = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                        label="Agent Email"
                        v-model="search.agent_email"
                        v-on:keyup.enter="searchAgentChangeRequest"
                        @input="search.agent_email = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select
                        label="Status"
                        v-model="search.status"
                        v-on:keyup.enter="searchAgentChangeRequest"
                        @input="search.status = $event !== null ? $event : ''"
                        :items="status"
                        item-text="name"
                        item-value="value"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="3" class="text-right">
                    <v-btn
                        @click.prevent="searchAgentChangeRequest"
                        v-on:keyup.enter="searchAgentChangeRequest"
                        class="btn btn-primary"
                        :loading="loading"
                    >
                      <v-icon small  outlined>fas fa-search</v-icon>
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Name</strong></th>
                  <th><strong>Email</strong></th>
                  <th><strong>Agent Email</strong></th>
                  <th v-if="currentUser && currentUser.access_type == 'head'">Branch</th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center" v-if="search.status == 'pending'"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <tr v-for="(agentChangeRequest, index) in agentChangeRequests" :key="index">
                    <td>
                      {{ agentChangeRequest.name }}
                    </td>

                    <td>
                      {{ agentChangeRequest.email }}
                    </td>

                    <td>
                      {{ agentChangeRequest.agent_email }}
                    </td>

                    <td class="px-2" v-if="currentUser && currentUser.access_type == 'head'">
                      {{agentChangeRequest.branch_name}}
                    </td>

                    <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-info': agentChangeRequest.status == 'pending', 'badge-success': agentChangeRequest.status == 'approved', 'badge-danger': agentChangeRequest.status == 'rejected' }"
                        >{{ agentChangeRequest.status_text }}</span>
                    </td>

                    <td class="pr-0 text-center" v-if="search.status == 'pending'">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('change-request', 'show')">
                              <a class="navi-link" @click="viewDocument(agentChangeRequest)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-file</v-icon>
                                  </span>
                                <span class="navi-text">View Document</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('change-request', 'edit')">
                              <a class="navi-link" @click="editAgentChangeRequest(agentChangeRequest)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                  </span>
                                <span class="navi-text">Approved/Rejected</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="agentChangeRequests.length == 0">
                    <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                  </tr>
                </template>

              </table>

            </div>
            <!-- table ends -->
          </div>
        </div>
      </div>
      <v-dialog
          v-model="dialog"
          width="800px" persistent>
        <v-card>
          <v-card-title>
            <span class="h5 font-weight-regular"></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <b>Reason For Change: </b>
                  <p>{{agentChangeRequest.reason_for_change}}</p>
                </v-col>
                <v-col cols="12">
                  <b>Remarks: </b>
                  <ckeditor :config="editorConfig" v-model="agentChangeRequest.remark">
                  </ckeditor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text class="cancel-btn"
                medium  x-large
                @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="isLoading" x-large dark color="red" @click="disapprovedAgentChangeRequest" rounded  v-if="agentChangeRequest.status == 'pending'">
              <v-icon right dark small>
                fas fa-times
              </v-icon> Not Approved
            </v-btn>
            <v-btn
                class="btn btn-primary"
                dark :loading="isLoading"
                x-large rounded
                @click="approvedAgentChangeRequest"
                v-if="agentChangeRequest.status == 'pending' && checkIsAccessible('change-request', 'edit')"
            >
              <v-icon right small dark>
                fas fa-check
              </v-icon> Approved
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>

import AgentChangeRequestService from "@/services/user/agent/change-request/AgentChangeRequestService";

const agentChangeRequest = new AgentChangeRequestService();

export default {
  data() {
    return{
      agentChangeRequests:[],
      agentChangeRequest: {
        status: '',
        remark: ''
      },
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isLoading: false,
      dialog: false,
      status: [
        {name: 'Pending', value: 'pending'},
        {name: 'Approved', value: 'approved'},
        {name: 'Rejected', value: 'rejected'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      search:{
        name:'',
        agent_email: '',
        status:'pending',
      }
    }
  },
  mounted() {
    this.getAllAgentChangeRequests();
  },
  methods: {

    getAllAgentChangeRequests(){
      this.loading = true;
      agentChangeRequest
        .paginate(this.search, this.page)
        .then(response => {
          this.agentChangeRequests = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    viewDocument(agentRequest) {
      if(agentRequest.agent_signed_document_path) {
        window.open(agentRequest.agent_signed_document_path.real, '_blank');
      }
    },

    editAgentChangeRequest(agentRequest) {
      this.dialog = true;
      this.agentChangeRequest = agentRequest;
    },

    approvedAgentChangeRequest() {
      this.isLoading = true;
      this.agentChangeRequest.status = 'approved';
      agentChangeRequest
          .update(this.agentChangeRequest.id, this.agentChangeRequest)
          .then(response => {
              this.$snotify.success("Approved Successfully");
              this.isLoading = false;
              this.dialog = false;
              this.getAllAgentChangeRequests();
          })
          .catch(() => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    disapprovedAgentChangeRequest() {
      this.isLoading = true;
      this.agentChangeRequest.status = 'rejected';
      agentChangeRequest
          .update(this.agentChangeRequest.id, this.agentChangeRequest)
          .then(response => {
            this.$snotify.success("Rejected Successfully");
            this.isLoading = false;
            this.dialog = false;
            this.getAllAgentChangeRequests();
          })
          .catch(() => {
            this.isLoading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchAgentChangeRequest(){
      this.page = 1;
      this.getAllAgentChangeRequests();
    }
  }
}
</script>
